/* eslint-disable vue/valid-v-else */
<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Payment</h2>
    </template>
    <template #action-bar></template>
    <template #page-content>
      <r-card>
        <div class="column flex-wrap m-0">
          <b-table
            :data="billingDatas.data ? billingDatas.data : []"
            :loading="isLoading"
            paginated
            backend-pagination
            :total="billingDatas.meta.total"
            :per-page="params.perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            backend-sorting
            @sort="onSort"
          >
            <b-table-column field="no" label="No" v-slot="props">
              {{ props.index + 1 }}
            </b-table-column>
            <b-table-column field="packageName" label="Service" v-slot="props">
              {{ props.row.package.packageName }}
            </b-table-column>
            <b-table-column
              field="billingCycle"
              label="Billing Cycle"
              v-slot="props"
            >
              {{ props.row.activeDate | billingIssue }}
            </b-table-column>
            <b-table-column
              field="billingDate"
              label="Billing Date Issue"
              v-slot="props"
            >
              {{ props.row.actualPayDate | moment }}
            </b-table-column>
            <b-table-column field="price" label="Amount" v-slot="props">
              Rp. {{ props.row.price | numeral }}
            </b-table-column>
            <b-table-column
              field="invoice"
              label="Invoice Number"
              v-slot="props"
            >
              {{ props.row.invoice }}
            </b-table-column>
            <b-table-column field="status" label="Status" v-slot="props">
              <b-tag rounded :type="paymentStatus(props.row.status)">
                <span style="text-transform: capitalize">
                  {{ props.row.status }}
                </span>
              </b-tag>
            </b-table-column>
            <b-table-column
              field="actualPayDate"
              label="Actual Pay Date"
              v-slot="props"
            >
              {{ props.row.actualPayDate | moment }}
            </b-table-column>
            <b-table-column label="Action" v-slot="props">
              <span v-if="props.row.status == 'unpaid'">
                <b-icon
                  v-if="props.row.paymentMethod != 'e_wallet'"
                  icon="cart"
                  class="has-text-primary"
                  style="cursor: pointer; margin-right: 5px"
                  @click.native="toCheckout(props.row.id)"
                ></b-icon>
                <b-icon
                  icon="close-box"
                  class="has-text-primary"
                  style="cursor: pointer"
                  @click.native="cancelInvoiceDialog(props.row.id)"
                ></b-icon>
              </span>
              <span v-else>-</span>
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    No data yet, please contact us if you have any problems
                    (hello@getrise.id)
                  </p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </r-card>
      <router-view />
    </template>
  </r-page>
</template>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'PackageActive',
  data() {
    return {
      billingDatas: {
        data: [],
        meta: {},
      },
      params: {
        page: 1,
        perPage: 10,
        orderBy: 'created_at',
        orderDirection: 'desc',
      },
      isLoading: false,
      packageActive: null,
    }
  },
  mounted() {
    let midtrans = document.createElement('script')
    midtrans.setAttribute(
      'src',
      'https://app.sandbox.midtrans.com/snap/snap.js'
    )
    midtrans.setAttribute(
      'data-client-key',
      process.env.VUE_APP_MIDTRANS_CLIENT_KEY
    )
    midtrans.async = true
    document.head.appendChild(midtrans)
  },
  async created() {
    await this.getPackageActive().then((response) => {
      this.packageActive = response
    })
    this.getData()
  },
  computed: {},

  methods: {
    ...mapActions({
      getBillingData: 'packageModule/getBilling',
      cancelBilling: 'payments/cancelBilling',
      getPackageActive: 'packageModule/getPackageActive',
    }),
    cancelInvoiceDialog(id) {
      this.$buefy.dialog.confirm({
        title: 'Canceling Payment',
        message: 'Are you sure want to cancel this payment ?',
        confirmText: 'Yes',
        cancelText: 'No',
        hasIcon: false,
        onConfirm: () => {
          this.cancelBilling(id).then(
            () => {
              this.$buefy.toast.open(`Payment Canceled!`)
              this.isLoading = true
              this.getData()
            },
            () => {
              this.$buefy.toast.open(`Oops, Something Wrong!`)
            }
          )
        },
      })
    },
    paymentStatus(status) {
      switch (status) {
        case 'unpaid':
          return 'is-danger'
        case 'paid':
          return 'is-success'
        case 'rejected':
          return 'is-warning'
        case 'past':
          return 'is-info'
        default:
          break
      }
    },
    async getData() {
      this.isLoading = true
      let data = await this.getBillingData(this.params)
      this.billingDatas.data = data.data
      this.billingDatas.meta = data.meta
      console.log(data)
      this.isLoading = false
    },
    toCheckout(idBilling) {
      let filter = this.billingDatas.data.filter((item) => {
        return item.id == idBilling
      })[0]

      if (filter) {
        if (
          filter.paymentMethod == 'Transfer BCA' ||
          filter.paymentMethod == '' ||
          filter.paymentMethod == null
        ) {
          this.$router.push({
            name: 'checkout-billing-package',
            params: { id: idBilling },
          })
        } else {
          window.snap.pay(filter.snapToken)
        }
      }
    },
    onPageChange(page) {
      this.params.page = page
      this.getData()
    },
    onSort(field, order) {
      this.params.orderBy = field
      this.params.orderDirection = order
      this.getData()
    },
  },
  filters: {
    moment: function (date) {
      if (date != null) {
        return moment(date).format('DD MMMM YYYY')
      }
      return '-'
    },
    numeral: function (val) {
      return new Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
      }).format(val)
    },
    billingIssue: function (date) {
      if (date != null) {
        let dateNow = moment(date).format('DD-MM-YYYY')
        var nextMonth = moment(dateNow, 'DD-MM-YYYY')
          .add(1, 'months')
          .format('MMMM')
        return nextMonth
      }
      return '-'
    },
  },
}
</script>
